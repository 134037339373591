import React from 'react';
import styled from 'styled-components';
import { Header, Icon } from 'semantic-ui-react';
import history from '../../constants/history';
import AddUsers from './AddUsers';
import RIAUsersTable from './RIAUsersTable';
import colors from '../../constants/colors';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
Wrapper.displayName = 'Wrapper';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 70%;
  max-width: 95%;
  align-self: center;
`;
Content.displayName = 'Content';

const SubHeader = styled.p`
  margin-bottom: 0px;
  font-size: 15px;
  color: ${colors.GRAY_2};
`;

const DelegateAccess = () => (
  <Wrapper>
    <Header size="huge" className="primary left-padded">
      <Header.Subheader className="back-link" onClick={() => history.goBack()}>
        <Icon name="left arrow" />
        Back
      </Header.Subheader>
      Authorized User Access
      <SubHeader>
        Add any Authorized Users here if your account is managed by an
        Investment Advisor. Please Contact
        <a href="mailto:clearus.membership@cboe.com">
          {' '}
          clearus.membership@cboe.com{' '}
        </a>
        if you have any questions.
      </SubHeader>
    </Header>
    <Content>
      <AddUsers />
      <RIAUsersTable />
    </Content>
  </Wrapper>
);

export default DelegateAccess;
